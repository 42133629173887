import React from "react";

function EarlyUse() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="65"
            fill="none"
            viewBox="0 0 65 65"
        >
            <mask
                id="mask0_1_154"
                style={{ maskType: "alpha" }}
                width="65"
                height="65"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H65V65H0z"></path>
            </mask>
            <g
                fill="#901316"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_1_154)"
            >
                <path d="M6.802 25.554a6.805 6.805 0 016.803 6.803 6.805 6.805 0 01-6.803 6.802A6.805 6.805 0 010 32.357a6.805 6.805 0 016.802-6.803zm0 4.535a2.268 2.268 0 10.002 4.537 2.268 2.268 0 00-.002-4.537zM58.198 25.554A6.805 6.805 0 0165 32.357a6.805 6.805 0 01-6.802 6.802 6.805 6.805 0 01-6.803-6.802 6.805 6.805 0 016.803-6.803zm0 4.535a2.268 2.268 0 10.001 4.537 2.268 2.268 0 00-.001-4.537z"></path>
                <path d="M53.663 30.09a2.268 2.268 0 010 4.534H52.64l-1.264 10.107a2.27 2.27 0 01-2.113 1.984A2.274 2.274 0 0146.927 45l-3.846-15.39-3.845 15.39a2.27 2.27 0 01-4.402 0l-3.846-15.39-3.846 15.39a2.27 2.27 0 01-4.402 0l-3.846-15.39-.822 3.296a2.27 2.27 0 01-2.201 1.718h-4.535a2.268 2.268 0 010-4.535h2.763l2.594-10.376a2.27 2.27 0 014.402 0l3.846 15.389 3.846-15.39a2.27 2.27 0 014.402 0l3.846 15.39 3.845-15.39a2.27 2.27 0 014.403 0l3.102 12.412.006-.049a2.268 2.268 0 012.249-1.986h3.023z"></path>
            </g>
        </svg>
    );
}

export default EarlyUse;
