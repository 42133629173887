import React from "react";

function RarelyRespond() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="65"
            fill="none"
            viewBox="0 0 65 65"
        >
            <mask
                id="mask0_1_141"
                style={{ maskType: "alpha" }}
                width="65"
                height="65"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H65V65H0z"></path>
            </mask>
            <g mask="url(#mask0_1_141)">
                <path
                    fill="#901316"
                    d="M58.5 7.363a4.96 4.96 0 00-2.108-4.055 5.023 5.023 0 00-4.554-.564l-18.326 6.9h-13.66a4.941 4.941 0 00-3.47 1.453 4.962 4.962 0 00-1.45 3.476v.704H11.42a4.943 4.943 0 00-3.47 1.452A4.963 4.963 0 006.5 20.207v11.265a4.962 4.962 0 001.449 3.477 4.94 4.94 0 003.47 1.452h3.513v.704a4.963 4.963 0 001.45 3.477 4.943 4.943 0 003.47 1.452h.983l2.052 14.337a6.282 6.282 0 002.094 3.84 6.26 6.26 0 004.09 1.539 6.264 6.264 0 004.765-2.156 6.291 6.291 0 001.475-5.026l-1.799-12.533 18.326 6.9c.55.203 1.13.308 1.715.31 1.012-.002 2-.306 2.839-.873a4.962 4.962 0 002.108-4.056V7.363zM11.418 32.177a.703.703 0 01-.703-.704V20.207c0-.389.315-.704.703-.704h3.513v12.674h-3.513zm7.73 4.929V14.574c0-.39.314-.704.702-.704h11.946v23.94H19.85a.704.704 0 01-.702-.705v.001zm11.524 19.715c-.4.449-.973.705-1.574.704a2.057 2.057 0 01-2.024-1.774l-1.967-13.716h4.188l1.799 13.152a2.034 2.034 0 01-.422 1.634zm23.611-12.505a.677.677 0 01-.309.563.673.673 0 01-.647 0l-17.314-6.506V13.025l17.314-6.506a.675.675 0 01.647 0 .678.678 0 01.31.563v37.234z"
                ></path>
            </g>
        </svg>
    );
}

export default RarelyRespond;
