import React from "react";

function HigherInterest() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="65"
            fill="none"
            viewBox="0 0 65 65"
        >
            <mask
                id="mask0_1_145"
                style={{ maskType: "alpha" }}
                width="65"
                height="65"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H65V65H0z"></path>
            </mask>
            <g fill="#901316" mask="url(#mask0_1_145)">
                <path d="M43.685 48.017c-1.85 0-5.567-.074-7.662-.276a1.79 1.79 0 11.34-3.563c1.868.176 5.407.259 7.322.259 10.126 0 17.08-2.109 17.689-5.298L43.685 11.773 35.687 24.14a1.796 1.796 0 01-2.478.532 1.794 1.794 0 01-.532-2.477L42.18 7.499a1.793 1.793 0 013.01 0l19.523 30.203c.188.289.287.628.287.973.003 5.936-7.766 9.343-21.315 9.343v-.001z"></path>
                <path d="M18.39 30.656c-8.736 0-17.58-2.654-17.58-7.729 0-10.148 35.165-10.148 35.165 0-.002 5.075-8.847 7.729-17.584 7.729zm0-11.874c-8.684 0-14.001 2.685-14.001 4.145 0 1.461 5.317 4.146 14.002 4.146s14.002-2.685 14.002-4.146c0-1.46-5.317-4.145-14.002-4.145zM18.39 58.316c-8.736 0-17.58-2.654-17.58-7.728 0-10.148 35.165-10.148 35.165 0-.002 5.074-8.847 7.728-17.584 7.728zm0-11.874c-8.684 0-14.001 2.685-14.001 4.146 0 1.46 5.317 4.145 14.002 4.145s14.002-2.684 14.002-4.145c0-1.461-5.317-4.146-14.002-4.146z"></path>
                <path d="M34.181 52.38c-.99 0-1.791-.803-1.791-1.793v-27.66a1.792 1.792 0 013.583 0v27.66c0 .99-.803 1.792-1.792 1.792zM2.599 52.38c-.99 0-1.792-.803-1.792-1.793v-27.66a1.792 1.792 0 013.584 0v27.66c0 .99-.803 1.792-1.792 1.792zM28.929 42.29c-.99 0-1.792-.803-1.792-1.792v-7.483a1.792 1.792 0 013.584 0v7.483c-.001.99-.803 1.791-1.792 1.791z"></path>
            </g>
        </svg>
    );
}

export default HigherInterest;
