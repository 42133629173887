import React from "react";

function Sensitivity() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="65"
            fill="none"
            viewBox="0 0 65 65"
        >
            <mask
                id="mask0_1_160"
                style={{ maskType: "alpha" }}
                width="65"
                height="65"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H65V65H0z"></path>
            </mask>
            <g fill="#901316" mask="url(#mask0_1_160)">
                <path d="M13.933 33.889c-1.601 1.473-3.97 3.778-2.945 8.003l.064.192c.96 2.497 3.073 3.97 5.57 3.97.64 0 1.345-.064 1.985-.32 4.098-1.217 7.748-5.763 6.531-11.909l-3.137.64c.832 4.29-1.537 7.428-4.29 8.196-.832.256-2.753.512-3.65-1.6-.512-2.242.449-3.33 2.049-4.803 1.408-1.345 3.585-3.393 1.857-6.658-.896-1.729-1.729-2.818-2.305-3.586-.704-.96-.96-1.345-1.088-1.985.064-4.225 3.522-7.619 7.747-7.619a7.733 7.733 0 017.748 7.747 7.839 7.839 0 01-2.754 5.955l2.05 2.433c2.496-2.113 3.905-5.122 3.905-8.388 0-6.018-4.93-10.948-10.949-10.948-6.018 0-10.948 4.93-10.948 10.948v.192c.192 1.537.896 2.497 1.728 3.586.576.768 1.28 1.728 2.05 3.201.51.96.383 1.28-1.218 2.753z"></path>
                <path d="M22.256 4.053c-6.21 0-11.204 2.304-14.79 6.85l2.497 1.986c3.01-3.778 7.043-5.635 12.293-5.635 9.028 0 16.39 7.363 16.39 16.39a16.53 16.53 0 01-6.402 13.062l-.064.064c-4.674 4.162-5.698 7.299-6.851 15.879-.96 3.137-3.906 5.442-7.363 5.442-4.226 0-7.684-3.457-7.684-7.683H7.081a10.876 10.876 0 0010.885 10.885A10.876 10.876 0 0028.85 50.408c.96-6.147 2.05-8.26 5.378-11.205 4.866-3.777 7.62-9.411 7.62-15.558 0-10.757-8.772-19.593-19.593-19.593h.001zM48.572 48.039c2.69-4.034 2.881-9.22.512-14.342l-2.881 1.344c1.92 4.098 1.793 8.196-.256 11.205-1.857 2.753-5.059 4.225-8.772 4.034l-.191 3.201h.896c4.417 0 8.323-1.985 10.692-5.442z"></path>
                <path d="M51.9 31.2c4.803 9.476 1.794 16.2-.383 19.4-3.266 4.61-8.708 7.3-13.638 6.723l-.385 3.202c.577.064 1.217.128 1.793.128 5.57 0 11.269-3.073 14.854-8.195 4.482-6.403 4.738-14.662.64-22.666l-2.88 1.408zM42.617 43.94c1.153-2.048 1.025-4.737-.32-6.658l-2.625 1.857c.64.96.769 2.241.192 3.202-.512.832-1.408 1.28-2.625 1.152l-.256 3.201h.64c2.177.064 3.97-.96 4.995-2.753z"></path>
            </g>
        </svg>
    );
}

export default Sensitivity;
