import React, { ReactElement } from "react";

import FeatureList from "@components/shared/featureList";
import PoorEye from "./icons/PoorEye";
import HigherInterest from "./icons/HigherInterest";
import EarlyUse from "./icons/EarlyUse";
import RarelyRespond from "./icons/RarelyRespond";
import Sensitivity from "./icons/Sensitivity";
const sideEffectList = [
    {
        title: "Poor Eye Contact, the Child May Not Focus on Faces",
        icon: <PoorEye />,
    },
    {
        title: "Higher Interest in Objects Over People",
        icon: <HigherInterest />,
    },
    {
        title: "Early Use of Ritual Behavior and Resistance to Change",
        icon: <EarlyUse />,
    },
    {
        title: "Rarely Respond to Their Name or Interact with Family Members",
        icon: <RarelyRespond />,
    },
    {
        title: "Sensitivity to Sound, Light or Textures",
        icon: <Sensitivity />,
    },
];

export default function SideEffects(): ReactElement {
    return (
        <FeatureList
            featureList={sideEffectList}
            mainTitle="Symptoms of Autism Syndrome Disorder"
            mainDescription="Children with autism may have many different symptoms and varying degrees of neurological impairments. Knowing the signs can help with the early diagnosis of autism spectrum disorder (ASD), which can improve the treatment and development options for the child. Some of the early symptoms of ASD in infants and toddlers may include:"
            classes={{
                mainSectionClasses:
                    "mx-auto  flex justify-center items-start flex-col  mb-10  px-5  ",
                mainTitleClasses:
                    "text-left font-bold md:leading-10 md:text-2xl text-xl leading-7 mb-5",
                innerSectionClasses: "flex flex-wrap  mt-10   gap-6 mb-14 ",
                oneBoxClasses: "flex  items-center  w-full md:w-96  mt-1",
                titleClasses: "text-sm md:text-lg  ml-8",
                mainDescriptionClasses:
                    "text-left text-sm md:text-lg leading-8 ",
                iconClasses: " ",
                descriptionClasses: "hidden",
            }}
            colors={{
                mainDescriptionColor: "#404040",
                mainTitleColor: "#901316",
                titleColor: "#404040",
            }}
        />
    );
}
